<template>
    <v-container
            fluid >
        <v-card
                elevation="0"
                class="px-3">
            <v-card-title
                    class="text-sm-h5 text-lg-h4">
                New Job
                <v-spacer></v-spacer>                            
            </v-card-title>
            
        </v-card>

        <v-card
                elevation="0"
                class="px-3 mt-3" >            
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: 'createNewJob'
}
</script>