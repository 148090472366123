import * as fb from "../../firebase";
import router from "@/router";

export default {
    state: {
        registeredClientMap: new Map(),
        registeredClientsList: [],
        invitedClientsMap: new Map(),
        invitedClientsList: [],

    },
    getters: {
        getRegisteredClientsList: state => state.registeredClientsList,
        getInvitedClientsList: state => state.invitedClientsList
    },
    mutations: {
        setRegisteredClientsMap(state, client){
            state.registeredClientMap.set(client.id, {...client.data(), id: client.id});
        },
        setRegisteredClientsList(state, clientMap) {
            state.registeredClientsList = Array.from(clientMap.values());
        },
        removeRegisteredClient(state, clientId){
            state.registeredClientMap.delete(clientId);
        },
        setInvitedClientsMap(state, client){
            state.invitedClientsMap.set(client.id, {...client.data(), id: client.id});
        },
        setInvitedClientsList(state, clientMap) {
            state.invitedClientsList = Array.from(clientMap.values());
        },
        removeInvitedClient(state, clientId){
            state.invitedClientsMap.delete(clientId);
        }
    },
    actions: {
        inviteNewClient({ dispatch }, client) {
            return new Promise((response, reject) => {
                console.log(client);
                fb.invitedUsersCollection.add({
                    name: client.name,
                    email: client.email,
                    clientType: client.clientType,
                    phoneNo: client.phoneNo,
                    officeName: client.officeName,
                    link: client.fdn,
                    addressLine: client.addressLine,
                    city: client.city,
                    state: client.state,
                    pinCode: client.pinCode,
                    createdOn: new Date(),
                    userRole: 'client'
                }).then(clientRef => {
                    console.log(clientRef.id);
                    let link = client.fdn + '/clientRegistration/' + clientRef.id;
                    fb.invitedUsersCollection.doc(clientRef.id).update({
                        link: link
                    }).then(() => {
                        dispatch('clientInvitationEmail', { ...client, regLink: link })
                            .then(() => {
                                response('Invitation sent successfully.');
                            }, error => {
                                reject(error)
                            })
                    })
                        .catch(error => {
                            console.log(error.message)
                            reject(error.message);
                        })
                })
                    .catch(error => {
                        console.log(error.message)
                        reject(error.message);
                    })
            })
        },
        createInvitedClientAccount({ commit }, form) {
            return new Promise((resolve, reject) => {
              fb.auth.createUserWithEmailAndPassword(form.email, form.password)
                .then(cred => {
                  //create user profile in usercollection
                  fb.userCollection.doc(cred.user.uid).set({
                    name: form.name,
                    email: form.email,
                    userRole: form.userRole,
                    createdOn: new Date(),
                    updatedOn: new Date(),
                    onlineStatus: 'Unavailable',
                    clientType: form.clientType,
                    phoneNo: form.phoneNo,
                    officeName: form.officeName,                    
                    addressLine: form.addressLine,
                    city: form.city,
                    state: form.state,
                    pinCode: form.pinCode,
                  }).then(() => {
                    fb.invitedUsersCollection.doc(form.invitationId).delete()
                      .then(() => {
                        fb.auth.signOut();
                        commit('setUser', {});
                        resolve('Account Created Successfully. Login to Continue');
                        router.push('/login');
                      })
                  })
                })
                .catch(error => {
                  reject(error.message);
                  //Todo: add logging mechanism to be stored on firebase for each account
                })
            })
          },


        fetchRegisteredClientsList({ state,commit }) {
            return new Promise((response, reject) => {
                fb.userCollection.where('userRole', '==', 'client').onSnapshot(snapshot => {
                    if (snapshot.empty) reject('Cannot find any registerd clients.');
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === "removed") {
                            commit('removeRegisteredClient', change.doc.id);
                        }
                    });
                    snapshot.forEach(doc => {
                        commit('setRegisteredClientsMap', doc)
                    })
                    commit('setRegisteredClientsList', state.registeredClientMap)                    
                    response('Fetched registered clients successfully');
                }, error => {
                    reject(error.message);
                })
            })
        },
        fetchInvitedClientsList({ state, commit }) {
            return new Promise((response, reject) => {
                fb.invitedUsersCollection.where('userRole', '==', 'client').onSnapshot(snapshot => {
                    if (snapshot.empty) reject('Cannot find any invited clients.');
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === "removed") {
                            commit('removeInvitedClient', change.doc.id);
                        }
                    });
                    snapshot.forEach(doc => {
                        commit('setInvitedClientsMap', doc)
                    })
                    commit('setInvitedClientsList', state.invitedClientsMap)    
                    response('Fetched invited clients successfully');
                }, error => {
                    reject(error.message);
                })
            })
        },
        updateRegisteredClient({state}, clientData){
            return new Promise((response, reject) => {
                fb.userCollection.doc(clientData.id).update(clientData)
                .then(() => {
                    response('Client Information updated successfully.')
                })
                .catch(error => {
                    reject(error.message);
                })
            })
        },
        updateInvitedClient({state}, clientData){
            return new Promise((response, reject) => {
                fb.invitedUsersCollection.doc(clientData.id).update(clientData)
                .then(() => {
                    response('Client Information updated successfully.')
                })
                .catch(error => {
                    reject(error.message);
                })
            })
        },
        deleteRegisteredClient({state}, clientId){
            return new Promise((response, reject) => {
                fb.userCollection.doc(clientId).delete()
                .then(() => {
                    response('Client Deleted successfully.')
                })
                .catch(error => {
                    reject(error.message);
                })
            })
        },
        deleteInvitedClient({state}, clientId){
            console.log(clientId)
            return new Promise((response, reject) => {
                fb.invitedUsersCollection.doc(clientId).delete()
                .then(() => {
                    response('Client Deleted successfully.')
                })
                .catch(error => {
                    reject(error.message);
                })
            })
        }

    }
}