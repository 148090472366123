<template>
<div>
    <adminDrawer v-if="this.getUser.data().userRole.toLowerCase() === 'admin'" ></adminDrawer>
    <generalDrawer v-else></generalDrawer>
    <navBar></navBar>
</div>
    
</template>

<script>
import { mapGetters } from 'vuex';
import adminDrawer from './drawers/adminDrawer.vue';
import generalDrawer from './drawers/generalDrawer.vue'
import navBar from './bar/bar.vue'

export default {
    name: 'navigation',
    components:{
        adminDrawer,
        generalDrawer,
        navBar
    },
    computed:{
        ...mapGetters(['getUser'])
    },
    
}
</script>