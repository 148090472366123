<template>
  <v-app-bar app flat light color="navBarColor">
    <v-app-bar-nav-icon @click="drawerState = !drawerState" class="d-flex d-sm-none"> </v-app-bar-nav-icon>
      <v-toolbar-title class="d-flex d-sm-none">RL Dental Lab</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <profileMenu></profileMenu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import profileMenu from "./profileMenu.vue";

export default {
  name: "bar",
  components: {
    profileMenu,
  },
  computed:{
        drawerState:{
            get() {return this.$store.getters.getDrawerState},
            set(v) {return this.$store.commit("setDrawerState", v)},
        }        
    },
  methods:{      
   
  }
};
</script>