<template>
  <v-data-table
    :headers="header"
    :loading="loading"
    :items="getInvitedClientsList"
  >
    <template v-slot:item.name="{ item }">
      {{ item.name }}
    </template>
    <template v-slot:item.email="{ item }">
      {{ item.email }}
    </template>
    <template v-slot:item.officeName="{ item }">
      {{ item.officeName }}
    </template>
    <template v-slot:item.clientType="{ item }">
      {{ item.clientType }}
    </template>
    <template v-slot:item.createdOn="{ item }">
      {{ item.createdOn | formatDate }}
    </template>
    <template v-slot:item.actions="{ item }">
      <editAndDeleteClient listType="invited" :clientObj="item">
      </editAndDeleteClient>
    </template>
    <template v-slot:item.inviteLink="{ item }">
      <v-btn small outlined color="secondary" text @click="copyLink(item)">
        <v-icon left small>mdi-content-copy</v-icon>
        Copy Link
      </v-btn>
      <input type="hidden" :id="'client' + item.id" :value="item.link" />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import editAndDeleteClient from '@/components/admin/clientManagement/editAndDeleteClient.vue'
import moment from "moment";
export default {
  name: "invitedClientsList",
  data() {
    return {
      loading: false,
      header: [
        { text: "Name", align: "start", value: "name" },
        { text: "Email", align: "start", value: "email" },
        { text: "Office Name", align: "start", value: "officeName" },
        { text: "Client Type", align: "center", value: "clientType" },
        { text: "Invited On", align: "center", value: "createdOn" },
        { text: "Actions", align: "center", value: "actions" },
        {
          text: "Invitation Link",
          align: "center",
          value: "inviteLink",
          sortable: false,
        },
      ],
    };
  },
  components: {
    editAndDeleteClient
  },
  computed: {
    ...mapGetters(["getInvitedClientsList"]),
  },
  methods: {
    copyLink(client) {
      let link = document.querySelector("#client" + client.id);
      link.setAttribute("type", "text");
      link.select();

      if (document.execCommand("copy")) {
        this.$store.dispatch("showSnackBar", {
          text: "Invitation link copied successfully",
          color: "success",
        });
      } else {
        this.$store.dispatch("showSnackBar", {
          text: "Link could not be copied.",
          color: "error",
        });
      }
      link.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
  mounted() {
    if (this.getInvitedClientsList.length === 0) {
      this.loading = true;
      this.$store.dispatch("fetchInvitedClientsList").then(
        (response) => {
          console.log(response);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    }
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
  },
};
</script>