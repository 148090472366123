<template>
  <v-app class="main">
    <navigation v-if="showNav"></navigation>
    <v-main>
      <v-container
        v-if="!appDataFetched"
        fluid
        class="d-flex justify-center fill-height"
      >
        <v-progress-circular
          :size="60"
          width="7"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-container>

      <router-view v-else> </router-view>
    </v-main>
    <rlFooter></rlFooter>
    <snackBar></snackBar>
  </v-app>
</template>

<script>
import rlFooter from "@/components/footer.vue";
import snackBar from "@/components/notifications/snackBar.vue";
import navigation from "@/components/navigation/navigation.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      appDataFetched: false,
      
    };
  },
  components: {
    rlFooter,
    snackBar,
    navigation,
  },
  computed: {
    ...mapGetters(["getAppData", 'getUser']),
    showNav() {
      return Object.keys(this.getUser).length > 1;
    },
  },
  beforeCreate() {
    
    this.$store.dispatch('detectDeviceWidth');
    this.$store.dispatch("fetchAppData").then((response) => {
      this.appDataFetched = true;
      if (this.getAppData.initiated) {
        this.$vuetify.theme.themes.light = this.getAppData.themes.light;
      }
      this.showContent = true;
    });
  },
};
</script>

<style>
    .main{
        background-color: #f5f5f5 !important;
    }
    
</style>

