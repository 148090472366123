<template>
  <v-dialog v-model="show" :max-width="isPhone ? '95%' : '40%'">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        elevation="0"
        class="d-none d-sm-flex"
        color="primary"
      >
        <v-icon left> mdi-account-plus </v-icon>
        Create New Client
      </v-btn>
      <v-btn
        v-bind="attrs"
        v-on="on"
        elevation="0"
        icon
        small
        class="d-flex d-sm-none"
        color="primary"
      >
        <v-icon> mdi-account-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <div style="position: absolute; align-self: end; right: 0">
        <v-btn icon @click="show = !show" class="ma-1">
          <v-icon color="red">mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="justify-center"> Create New Client </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear
        v-show="loading"
        indeterminate
        color="primary"
        background-opacity="0"
      >
      </v-progress-linear>
      <v-card :max-height="viewPortHeight / 1.4"  class="overflow-y-auto overflow-x-hidden px-6 py-3">
        
        <v-form ref="inviteNewClientForm" lazy-validation >
          <v-select
            v-model="client.clientType"
            :items="clientType"
            :rules="[(v) => !!v || 'Client type is required']"
            label="Client Type"
            required
          >
          </v-select>
          <v-text-field
            v-model="client.name"
            label="Full Name"
            :rules="rules.nameRules"
            :prefix="client.clientType == 'Doctor' ? 'Dr.' : ''"
            required                                   
            autocomplete="chrome-off"
          >
          </v-text-field>
          <v-text-field
            v-model="client.email"
            label="E-mail"
            :rules="rules.emailRules"
            required          
            autocomplete="chrome-off"  
          >
          </v-text-field>
          <v-text-field
            v-model="client.phoneNo"
            label="Phone Number"
            :rules="rules.phoneRule"
            required
            type="number"
            hide-spin-buttons            
            autocomplete="chrome-off"
          >
          </v-text-field>
          <v-text-field
            v-if="client.clientType == 'Doctor'"
            v-model="client.officeName"
            label="Doctor's Office Name"
            :rules="rules.OfficeNameRule"
            required
            autocomplete="chrome-off"
          >
          </v-text-field>
          <v-text-field
            v-if="client.clientType == 'Vendor'"
            v-model="client.officeName"
            label="Vendor's Agency Name"
            :rules="rules.OfficeNameRule"
            required
            autocomplete="chrome-off"
          >
          </v-text-field>
          <v-row no-gutters v-if="client.clientType !== null">
            <v-col cols="12">
              <v-text-field
                dense
                v-model="client.addressLine"
                label="Address Line"
                :rules="rules.addressOneRule"
                required
                autocomplete="chrome-off"                
                prepend-icon="mdi-map-marker-outline"
              >
              </v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                dense
                v-model="client.city"
                :rules="rules.generalRule"
                label="City"
                autocomplete="chrome-off"
                prepend-icon="mdi-map-marker-outline"
              >
              </v-text-field
            ></v-col>
            <v-col md="6" cols="12">              
              <v-autocomplete
                dense                
                v-model="client.state"
                :rules="rules.generalRule"
                :items="states"
                label="State/Region"
                placeholder="Select..."
                required        
                autocomplete="chrome-off"        
                prepend-icon="mdi-map-marker-outline"
              ></v-autocomplete>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                dense
                v-model="client.pinCode"
                :rules="rules.generalRule"                
                label="Pin/Postal Code"
                autocomplete="chrome-off"
                prepend-icon="mdi-map-marker-outline"
              >
              </v-text-field
            ></v-col>            
          </v-row>

          <v-btn
            color="primary"
            dark
            block
            large
            elevation="0"
            @click="inviteNewUser()"
          >
            Create and Invite Client
          </v-btn>
        </v-form>
      </v-card>
      
      <v-divider></v-divider>
      <v-card-text align="center" class="caption">
        <p>
          Invitation link sent to the client's email will be use to set the
          password and activate the account.
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "createNewUser",
  data() {
    return {
      show: null,
      loading: false,
      client: {
        name: null,
        email: null,
        phoneNo: null,
        clientType: null,
        officeName: null,
        fdn: window.location.origin,
        addressLine: null,
        city: null,
        state: null,
        pinCode: null,        
      },
      rules: {
        nameRules: [(v) => !!v || "Full name is required"],
        OfficeNameRule: [(v) => !!v || "Office Name is required"],
        addressOneRule: [(v) => !!v || "Address Line is required"],
        phoneRule: [(v) => !!v || "Phone Number is required",
        (v) => (v && v.length == 10) || "Invalid Phone Number.",],
        generalRule: [(v) => !!v || "This field is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
      },
      roles: ["Admin", "General"],
      clientType: ["Doctor", "Vendor"],      
      states: [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Lakshadweep",
        "Puducherry",
      ],
    };
  },
  computed: {
    ...mapGetters(["isPhone"]),
    viewPortHeight() {
      return window.innerHeight;
    },
  },
  watch:{
    show(o,n){
      if(n){
        this.$refs.inviteNewClientForm.reset();
      }
    }
  },
  methods: {
    inviteNewUser() {
      if (this.$refs.inviteNewClientForm.validate()) {
        this.loading = true;
        this.$store.dispatch("inviteNewClient", this.client).then(
          (response) => {
            this.loading = false;
            this.$refs.inviteNewClientForm.reset();
            this.show = false;
            this.$store.dispatch("showSnackBar", {
              text: response,
              color: "green",
            });
          },
          (error) => {
            this.$store.dispatch("showSnackBar", {
              text: error,
              color: "error",
            });
          }
        );
      }
    },
  },
  
};
</script>