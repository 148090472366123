<template>
  <v-navigation-drawer v-model="drawerState" clipped-left app>
    <v-app-bar flat>
      <v-img
        src="@/assets/logo.png"
        contain
        justify="center"
        max-height="50"
        max-width="200"
        class="my-auto"
      >
      </v-img>
    </v-app-bar>

    <v-divider></v-divider>
    <v-list dense>
      <v-list-item to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title> Home </v-list-item-title>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-group prepend-icon="mdi-account-circle" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title> Manage </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="(item,i) in manage" :key="i" :to="item.link">
          <v-list-item-content>
            <v-list-item-title v-text="item.title"> </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-text="item.icon"> </v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
      </v-list-group>
      <v-divider></v-divider>
      <v-list-group prepend-icon="mdi-card-bulleted-settings" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title> Jobs </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item v-for="(item,i) in jobs" :key="i" :to="item.link">
          <v-list-item-content>
            <v-list-item-title v-text="item.title"> </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon v-text="item.icon"> </v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
      </v-list-group>
      <v-divider></v-divider>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "adminDrawer",
  data() {
    return {
      selectedItem: 1,
      manage: [
        {
          title: "Users",
          icon: "mdi-account-group-outline",
          link: "/userManagement",
        },        
        {
          title: "Clients",
          icon: "mdi-account-multiple-outline",
          link: "/clientManagement",
        },
        {
          title: "Products",
          icon: "mdi-format-list-bulleted",
          link: "/productManagement",
        },
        // {
        //   title: "Roles",
        //   icon: "mdi-card-account-details-outline",
        //   link: "/roleManagement",
        // },        
      ],
      jobs: [
        {
          title: 'New Job',
          icon: 'mdi-card-plus-outline',
          link: '/createJob'
        },
        {
          title: 'Job List',
          icon: 'mdi-format-list-bulleted',
          link: '/jobManagement'
        }
      ]
    };
  },
  computed: {
    drawerState: {
      get() {
        return this.$store.getters.getDrawerState;
      },
      set(v) {
        return this.$store.commit("setDrawerState", v);
      },
    },
  },
  methods: {},
};
</script>