<template>
  <v-dialog v-model="show" :width="isPhone ? '95%' : '40%'">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editClient">
        mdi-pencil
      </v-icon>
      <v-icon v-bind="attrs" v-on="on" small @click="deleteClient">
        mdi-delete
      </v-icon>
    </template>
    <v-card>
      <div style="position: absolute; align-self: end; right: 0">
        <v-btn icon @click="show = !show" class="ma-1">
          <v-icon color="red">mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="justify-center">
        {{ formTitle }}
      </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear
        v-show="loading"
        indeterminate
        color="primary"
        background-opacity="0"
      >
      </v-progress-linear>
      <v-card-text v-if="actionType === 'edit'">
        <v-form ref="editClientForm" lazy-validation class="px-6">
          <v-select
            v-model="client.clientType"
            :items="clientType"
            :rules="[(v) => !!v || 'Client type is required']"
            label="Client Type"
            required
          >
          </v-select>
          <v-text-field
            v-model="client.name"
            label="Full Name"
            :rules="rules.nameRules"
            :prefix="client.clientType == 'Doctor' ? 'Dr.' : ''"
            required
            autocomplete="chrome-off"
          >
          </v-text-field>
          <v-text-field
            v-model="client.email"
            label="E-mail"
            :rules="rules.emailRules"
            required
            disabled
            autocomplete="chrome-off"
          >
          </v-text-field>
          <v-text-field
            v-model="client.phoneNo"
            label="Phone Number"
            :rules="rules.phoneRule"
            required
            type="number"
            hide-spin-buttons
            autocomplete="chrome-off"
          >
          </v-text-field>
          <v-text-field
            v-if="client.clientType == 'Doctor'"
            v-model="client.officeName"
            label="Doctor's Office Name"
            :rules="rules.OfficeNameRule"
            required
            autocomplete="chrome-off"
          >
          </v-text-field>
          <v-text-field
            v-if="client.clientType == 'Vendor'"
            v-model="client.clientOfficeName"
            label="Vendor's Agency Name"
            :rules="rules.OfficeNameRule"
            required
            autocomplete="chrome-off"
          >
          </v-text-field>
          <v-row no-gutters v-if="client.clientType !== null">
            <v-col cols="12">
              <v-text-field
                dense
                v-model="client.addressLine"
                label="Address Line"
                :rules="rules.addressOneRule"
                required
                autocomplete="chrome-off"
                prepend-icon="mdi-map-marker-outline"
              >
              </v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                dense
                v-model="client.city"
                :rules="rules.generalRule"
                label="City"
                autocomplete="chrome-off"
                prepend-icon="mdi-map-marker-outline"
              >
              </v-text-field
            ></v-col>
            <v-col md="6" cols="12">
              <v-autocomplete
                dense
                v-model="client.state"
                :rules="rules.generalRule"
                :items="states"
                label="State/Region"
                placeholder="Select..."
                required
                autocomplete="chrome-off"
                prepend-icon="mdi-map-marker-outline"
              ></v-autocomplete>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                dense
                v-model="client.pinCode"
                :rules="rules.generalRule"
                label="Pin/Postal Code"
                autocomplete="chrome-off"
                prepend-icon="mdi-map-marker-outline"
              >
              </v-text-field
            ></v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined text @click="show = !show">
              Cancel
            </v-btn>
            <v-btn color="primary" elevation="0" @click="updateClient">
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="actionType === 'delete'">
        <v-spacer></v-spacer>
        <v-btn
          color="primary "
          outlined
          elevation="0"
          text
          @click="show = !show"
          >Cancel</v-btn
        >
        <v-btn color="primary " elevation="0" @click="confirmDeleteClient"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "editAndDeleteClient",
  props: {
    clientObj: Object,
    listType: String,
  },
  data() {
    return {
      show: null,
      actionType: null,
      formTitle: null,
      loading: false,
      clientType: ["Doctor", "Vendor"],
      client: {
        name: null,
        email: null,
        phoneNo: null,
        clientType: null,
        officeName: null,        
        addressLine: null,
        city: null,
        state: null,
        pinCode: null,
        updatedOn: new Date(),
        id: null
      },
      rules: {
        nameRules: [(v) => !!v || "Full name is required"],
        OfficeNameRule: [(v) => !!v || "Office Name is required"],
        addressOneRule: [(v) => !!v || "Address Line is required"],
        phoneRule: [(v) => !!v || "Phone Number is required",
        (v) => (v && v.length == 10) || "Invalid Phone Number.",],
        generalRule: [(v) => !!v || "This field is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
      },
      states: [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Lakshadweep",
        "Puducherry",
      ],
    };
  },
  computed: {
    ...mapGetters(["isPhone"]),
  },
  watch:{
    show(o,n){
      if(n && this.actionType == 'edit'){
        this.$refs.editClientForm.reset();
      }
    }
  },
  methods: {
    editClient() {
      this.formTitle = "Edit Client";
      this.actionType = "edit";
      this.client = Object.assign({}, this.clientObj);
      this.client.id = this.clientObj.id;
      console.log(this.client);
    },
    deleteClient() {
      this.formTitle = "Delete Client";
      this.actionType = "delete";
    },
    updateClient(){
        if(this.$refs.editClientForm.validate()){
          this.loading = true;
          if(this.listType === 'registered'){
            this.$store.dispatch('updateRegisteredClient', this.client)
              .then(response => {
                this.loading = false;
                this.show=false;
                this.$store.dispatch('showSnackBar', {
                  text: response,
                  color: 'success'
                })
              }, error => {
                this.$store.dispatch('showSnackBar', {
                  text: error,
                  color: 'error'
                })
              })
          }
          if(this.listType === 'invited'){
            this.$store.dispatch('updateInvitedClient', this.client)
              .then(response => {
                this.loading = false;
                this.show=false;
                this.$store.dispatch('showSnackBar', {
                  text: response,
                  color: 'success'
                })
              }, error => {
                this.$store.dispatch('showSnackBar', {
                  text: error,
                  color: 'error'
                })
              })
          }

        }
      },
      confirmDeleteClient(){
        this.loading = true;
        if(this.listType === 'registered'){
          this.$store.dispatch('deleteRegisteredClient', this.clientObj.id)
            .then(response => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch('showSnackBar', {
                text: response,
                color: 'success'
              })
            }, error => {
              this.loading = false;
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error'
              })
            })
        }
        if(this.listType === 'invited'){
          this.$store.dispatch('deleteInvitedClient', this.clientObj.id)
            .then(response => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch('showSnackBar', {
                text: response,
                color: 'success'
              })
            }, error => {
              this.loading = false;
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error'
              })
            })
        }
      }
  },
};
</script>