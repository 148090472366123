// import router from "../../router";
// import store from "../index";
import * as fb from '@/firebase/index.js'

export default {
    state: {
        appData: {},
        snackBar: {},
        drawerState: false,
        isPhone: false,


    },
    getters: {
        getAppData: state => state.appData,
        snackBar: state => state.snackBar,
        getDrawerState: state => state.drawerState,
        isPhone: state => state.isPhone,

    },
    mutations: {
        setAppData(state, data) {
            state.appData = data;
        },
        setSnackBar(state, snackBar) {
            state.snackBar = snackBar;
        },
        setDrawerState(state, value) {
            state.drawerState = value;
        },
        setIsPhone(state, value){
            state.isPhone = value;
        }
    },
    actions: {
        detectDeviceWidth({ commit }) {
            console.log(`Device width: ${window.innerWidth}`);
            if (window.innerWidth > 600) {
                commit('setDrawerState', true);
                commit('setIsPhone', false);
                console.log('Device is not a Phone')                
            }
            else{
                commit('setIsPhone', true);
            }
        },
        fetchAppData({ commit }) {
            return new Promise((resolve, reject) => {

                fb.appDataCollection.get()
                    .then((querySnapshot) => {
                        if (querySnapshot.empty) {
                            commit('setAppData', { initiated: false });
                            resolve('not initiated')
                        }
                        else {
                            fb.appDataCollection.doc('config').get()
                                .then(appData => {
                                    commit('setAppData', appData.data());
                                    resolve('fetched');
                                })
                        }
                    })
                    .catch(error => {
                        reject(error.message);
                        //Todo: add logging mechanism to be stored on firebase for each account
                    })
            })
        },
        initiateAppData() {
            return new Promise((resolve, reject) => {
                fb.appDataCollection.get()
                    .then((querySnapshot) => {
                        if (querySnapshot.empty) {
                            fb.appDataCollection.doc('config').set({
                                initiated: true,
                                themes: {
                                    light: {
                                        primary: '#FFC107',
                                        secondary: '#424242',
                                        accent: '#82B1FF',
                                        error: '#FF5252',
                                        info: '#2196F3',
                                        success: '#4CAF50',
                                        warning: '#FFC107',
                                        navBarColor: '#1976D2',
                                    },
                                }
                            }).then(() => {
                                resolve('initiated');
                            })
                                .catch(error => {
                                    reject(error.message)
                                })
                        }
                    })
                    .catch(error => {
                        reject(error.message)
                    })
            })
        },
        showSnackBar({ commit }, snackBar) {
            snackBar.show = true;
            snackBar.color = (snackBar.color === undefined) ? 'grey darken-2' : snackBar.color;
            snackBar.timeout = (snackBar.timeout === undefined) ? 5000 : snackBar.timeout;
            snackBar.icon = (snackBar.icon === undefined) ? 'mdi-information-outline' : snackBar.icon;
            commit('setSnackBar', snackBar);
        },
        showAnnouncement({ commit }, announcement) {
            announcement.show = true;
            announcement.color = (announcement.color === undefined) ? 'amber darken-1' : announcement.color;
            announcement.timeout = (announcement.timeout === undefined) ? 0 : announcement.timeout;
            announcement.icon = (announcement.icon === undefined) ? 'mdi-information-outline' : announcement.icon;
            commit('setAnnouncement', announcement);
        }




    }
}