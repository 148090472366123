<template>
  <v-data-table
    :headers="header"
    :loading="loading"
    :items="getInvitedUsersList"
  >
    <template v-slot:item.name="{ item }">
      {{ item.data().name }}
    </template>
    <template v-slot:item.email="{ item }">
      {{ item.data().email }}
    </template>
    <template v-slot:item.userRole="{ item }">
      {{ item.data().userRole }}
    </template>    
    <template v-slot:item.createdOn="{ item }">
      {{ item.data().createdOn | formatDate }}
    </template>
    <template v-slot:item.actions="{ item }">
      <editAndDeleteUser listType="invited" :userObj="item">
      </editAndDeleteUser>
    </template>
    <template v-slot:item.inviteLink="{ item }">
      <v-btn small outlined color="secondary" text @click="copyLink(item)">
        <v-icon left small>mdi-content-copy</v-icon>
        Copy Link
      </v-btn>
      <input
        type="hidden"
        :id="'user' + item.id"
        :value="item.data().link"
      />      
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import editAndDeleteUser from "@/components/admin/userManagement/editAndDeleteUser.vue";

export default {
  name: "invitedUsersList",
  data() {
    return {
      loading: false,
      header: [
        { text: "Name", align: "start", value: "name" },
        { text: "Email", value: "email" },
        { text: "Roles", value: "userRole" },
        { text: "Invited On", align: "center", value: "createdOn" },
        { text: "Actions", value: "actions", align: "center", sortable: false },
        { text: "Invitation Link", align: "center", value: "inviteLink", sortable: false },
      ],
    };
  },
  components:{
      editAndDeleteUser
  },
  computed: {
    ...mapGetters(["getInvitedUsersList"]),
  },
  methods: {
      copyLink(user){
          let link = document.querySelector('#user'+ user.id);
          link.setAttribute('type', 'text');
          link.select();

          if(document.execCommand('copy')){
            this.$store.dispatch('showSnackBar', {
              text: 'Invitation link copied successfully',
              color: 'success'
            })
          }
          else{
            this.$store.dispatch('showSnackBar', {
              text: 'Link could not be copied.',
              color: 'error'
            })
          }
          link.setAttribute('type', 'hidden')
          window.getSelection().removeAllRanges()
        }
  },
  mounted() {
    if (this.getInvitedUsersList.length === 0) {
      this.loading = true;
      this.$store.dispatch("fetchInvitedUsersList").then(
        (response) => {
          console.log(response);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    }
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
  },
};
</script>