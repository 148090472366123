<template>
    <v-container
            fluid >
        <v-card
                elevation="0"
                class="px-3">
            <v-card-title
                    class="text-sm-h5 text-lg-h4">
                Product Management
                <v-spacer></v-spacer>
                <createNewProduct></createNewProduct>
                
            </v-card-title>
            
        </v-card>

        <v-card
                elevation="0"
                class="px-3 mt-3" >
            <productList></productList>
        </v-card>
    </v-container>
</template>

<script>
import createNewProduct from '@/components/admin/productManagement/createNewProduct.vue';
import productList from '@/components/admin/productManagement/productList.vue';

export default {
    name: 'productManagement',
    components:{
        createNewProduct,
        productList
    }
}
</script>