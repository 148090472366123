<template>
    <v-container
            fluid >
        <v-card
                elevation="0"
                class="px-3">
            <v-card-title
                    class="text-sm-h5 text-lg-h4">
                User Management
                <v-spacer></v-spacer>
                <createNewUser></createNewUser>
            </v-card-title>
            
        </v-card>

        <v-card
                elevation="0"
                class="px-3 mt-3" >
            <v-tabs v-model="tab">
                <v-tab>Registered Users</v-tab>
                <v-tab>Invited Users</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">

                <v-tab-item>
                    <registeredUsersList></registeredUsersList>
                    
                </v-tab-item>
                <v-tab-item>
                    <invitedUsersList></invitedUsersList>
                    
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>

<script>
import registeredUsersList from '@/components/admin/userManagement/registeredUsersList.vue'
import invitedUsersList from '@/components/admin/userManagement/invitedUsersList.vue'
import createNewUser from '@/components/admin/userManagement/createNewUser.vue'



export default {
    name: 'userManagement',
    data(){
        return{
            tab: null
        }
    },
    components:{
        registeredUsersList,
        invitedUsersList,
        createNewUser

    }
}
</script>