var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.header,"loading":_vm.loading,"items":_vm.getAllProductList,"item-key":"name","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search by Product Name"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.updatedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.updatedOn))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" ₹ "+_vm._s(item.price)+"/- ")]}},{key:"item.warrantySupported",fn:function(ref){
var item = ref.item;
return [(item.warrantySupported)?_c('v-menu',{attrs:{"max-height":"300","bottom":"","right":"","transition":"scale-transition","origin":"top left"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return _vm._l((item.warrantys),function(warranty,index){return _c('span',_vm._g({key:index},on),[(index === 0)?_c('v-chip',{staticClass:"mr-1",attrs:{"outlined":"","color":"success darken-1","small":""}},[_c('span',[_vm._v(_vm._s(warranty.warrantyTermLength)+" "+_vm._s(warranty.warrantyTerm))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+ "+_vm._s(item.warrantys.length - 1)+" more) ")]):_vm._e()],1)})}}],null,true)},[_c('v-card',{attrs:{"width":"200"}},[_c('v-list',_vm._l((item.warrantys),function(warranty,index){return _c('v-list-item',{key:index},[_c('v-row',[_c('v-col',[_c('v-chip',{attrs:{"outlined":"","color":"success darken-1","small":""}},[_c('span',[_vm._v(_vm._s(warranty.warrantyTermLength)+" "+_vm._s(warranty.warrantyTerm)+" ")])])],1),_c('v-col',[_vm._v(" ₹ "+_vm._s(warranty.warrantyPrice)+"/- ")])],1)],1)}),1)],1)],1):_c('v-chip',{attrs:{"small":"","outlined":"","color":"error"}},[_vm._v(" No ")])]}},{key:"item.isEnabled",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.isEnabled,"color":"success","inset":"","dense":""},on:{"change":function($event){return _vm.productStateChange(item)}}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('editAndDeleteProduct',{attrs:{"productObj":item}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }