<template>
  <v-card class="my-5 px-2" elevation="0" outlined >
    <div style="position: absolute; align-self: start;">
        <v-btn icon @click="goBack" class="my-3 ml-4">
          <v-icon color="primary darken-1">mdi-arrow-left</v-icon>
        </v-btn>
      </div>
    <v-card-title class="justify-center">
      Custom Product Price List
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row no-gutters justify="center">
        <v-col cols="12" md="3">
          <strong> Client Name:</strong>
          <span v-if="clientObj.clientType.toLowerCase() === 'doctor'">
            Dr.
          </span>
          {{ this.clientObj.name }}
        </v-col>
        <v-col cols="12" md="2">
          <strong> Client Type:</strong>
          {{ this.clientObj.clientType }}
        </v-col>
        <v-col cols="12" md="2">
          <strong> Client Joined:</strong>
          {{ this.clientObj.createdOn | formatDate }}</v-col
        >
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary darken-1"
    ></v-progress-linear>
    <v-card-text>
      <div v-if="isPhone" class="d-flex align-center justify-center">
        Please use this section on larger screen.
        <v-btn @click="goBack" color="primary" width="100" elevation="0"
          >Cancel</v-btn
        >
      </div>
      <v-row v-else>
        <v-col cols="12" md="10">
          <v-row>
            <v-col
              class="subtitle-1 font-weight-bold text-decoration-underline"
              md="2"
              align="center"
              >Product Name</v-col
            >
            <v-col
              class="subtitle-1 font-weight-bold text-decoration-underline"
              md="2"
              align="center"
              >Default Price</v-col
            >
            <v-col
              class="subtitle-1 font-weight-bold text-decoration-underline"
              align="center"
              >Warranty Details</v-col
            >
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-virtual-scroll
            :items="customProductList"
            :height="viewPortHeight / 2.2"
            item-height="64"
            :key="forceRerender"
          >
            <template v-slot:default="{ item }">
              <v-list-item :key="item.id">
                <v-row>                  
                  <v-col cols="3" md="2" class="d-flex px-0">
                    <v-checkbox
                      v-model="item.custom"   
                      hide-details=""                       
                      class="ma-0 pa-0"          
                    ></v-checkbox>
                    <strong :class="item.custom ? 'error--text' : ''">{{
                      item.name
                    }}</strong></v-col
                  >
                  <v-col md="2" cols="4"
                    ><v-text-field
                      :background-color="item.custom ? 'error lighten-5' : ''"
                      hide-details
                      outlined
                      dense
                      prefix="₹"
                      v-model="item.price"
                    >
                    </v-text-field
                  ></v-col>
                  <v-col>
                    <v-row>
                      <v-col
                        md="2"
                        v-for="(warranty, index) in item.warrantys"
                        :key="index"
                      >
                        <v-text-field
                          :background-color="
                            item.custom ? 'error lighten-5' : ''
                          "
                          hide-details
                          outlined
                          prefix="₹"
                          dense
                          :label="`${warranty.warrantyTermLength} ${warranty.warrantyTerm} `"
                          v-model="warranty.warrantyPrice"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-col>
        <v-col cols="12" md="2" align="end">
          <v-btn
            @click="saveCustomList"
            color="primary"
            elevation="0"
            block
            width="100"
            class="mb-2 mt-9"
            >Save</v-btn
          >
          <v-btn
            @click="goBack"
            outlined
            block
            color="primary"
            width="100"
            elevation="0"
            >Cancel</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "productCustomPrice",
  props: {
    clientObj: Object,
  },
  data() {
    return {
      loading: false,
      forceRerender: 0,
    };
  },
  computed: {
    ...mapGetters(["isPhone", "getAllProductList", "getAllProductMap"]),
    viewPortHeight() {
      return window.innerHeight;
    },
    customProductList() {
      let tempMap = new Map();
      if (
        Object.prototype.hasOwnProperty.call(
          this.clientObj,
          "customProductList"
        )
      ) {
        this.clientObj.customProductList.forEach((item) => {
          if (this.getAllProductMap.has(item.id)) {
            tempMap.set(item.id, JSON.parse(JSON.stringify({ ...item, custom: true })));
          }
        });
        this.getAllProductList.forEach((item) => {
          if (!item.isEnabled && tempMap.has(item.id)) {
            tempMap.delete(item.id);
          }
          if (!tempMap.has(item.id) && item.isEnabled) {
            tempMap.set(item.id, JSON.parse(JSON.stringify({ ...item, custom: false }))  );
          }
        });
        return Array.from(tempMap.values());
      } else {
        return this.getAllProductList.map((item) => {
          return { ...item };
        });
      }
    },
  },
  methods: {
    goBack() {
      this.$emit("goBack", false);
    },
    saveCustomList() {
      this.loading = true;
      // const finalCustomProductList = this.customProductList.filter(
      //   (item) =>
      //     !this.getAllProductList.find(
      //       (prod) => item.price === prod.price && item.id === prod.id
      //     )
      // );
      const finalCustomProductList = this.customProductList.filter(item => item.custom === true);
      // console.log(finalCustomProductList);
      // if (finalCustomProductList.length !== 0) {
        this.$store
          .dispatch("updateCustomPrice", {
            userId: this.clientObj.id,
            productList: finalCustomProductList,
          })
          .then(
            (response) => {
              this.loading = false;
              this.forceRerender += 1;
              this.$store.dispatch("showSnackBar", {
                text: response,
                color: "success",
              });
            },
            (error) => {
              this.loading = false;
              this.$store.dispatch("showSnackBar", {
                text: error,
                color: "error",
              });
            }
          );
      // } else {
      //   this.loading = false;
      //   this.$store.dispatch("showSnackBar", {
      //     text: "Nothing to update",
      //     color: "error",
      //   });
      // }
    },
  },
  mounted() {
    if (this.getAllProductList.length === 0) {
      this.loading = true;
      this.$store.dispatch("fetchProductList").then(
        () => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    }
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
  },
};
</script>