var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.showProductCustomPrice)?_c('v-data-table',{attrs:{"headers":_vm.header,"loading":_vm.loading,"items":_vm.getRegisteredClientsList,"item-key":"name","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search by Client Name"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.clientType === 'Doctor')?_c('span',[_vm._v("Dr.")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.officeName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.officeName)+" ")]}},{key:"item.clientType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.clientType)+" ")]}},{key:"item.onlineStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","small":"","color":_vm.getColor(item.onlineStatus)}},[_vm._v(" "+_vm._s(item.onlineStatus)+" ")])]}},{key:"item.last_changed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.last_changed))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('editAndDeleteClient',{attrs:{"listType":"registered","clientObj":item}}),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","x-small":""},on:{"click":function($event){return _vm.displayProductCustomPrice(item)}}},[_vm._v(" Price List ")])]}}],null,false,3988302243)}):_vm._e(),(_vm.showProductCustomPrice)?_c('productCustomPrice',{attrs:{"clientObj":_vm.selectedClientObj},on:{"goBack":function($event){_vm.showProductCustomPrice = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }