<template>
  <div>
    <adminHome v-if="getUser.data().userRole.toLowerCase() === 'admin'"></adminHome>
    <generalHome v-else ></generalHome>
      
    
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import adminHome from '@/views/admin/adminHome.vue';
import generalHome from '@/views/general/generalHome.vue'


export default {
  name: "home",
  components: {
    
    adminHome,
    generalHome
    
  },
  data(){
    return{
      value: ''
    }
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
};
</script>