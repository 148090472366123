<template>
  <div>
    <v-data-table
      v-if="!showProductCustomPrice"
      :headers="header"
      :loading="loading"
      :items="getRegisteredClientsList"
      item-key="name" :search="search"
    >
    <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search by Client Name"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.name="{ item }">
        <span v-if="item.clientType === 'Doctor'">Dr.</span>
        {{ item.name }}
      </template>
      <template v-slot:item.email="{ item }">
        {{ item.email }}
      </template>
      <template v-slot:item.officeName="{ item }">
        {{ item.officeName }}
      </template>
      <template v-slot:item.clientType="{ item }">
        {{ item.clientType }}
      </template>
      <template v-slot:item.onlineStatus="{ item }">
        <v-chip dark small :color="getColor(item.onlineStatus)">
          {{ item.onlineStatus }}
        </v-chip>
      </template>
      <template v-slot:item.last_changed="{ item }">
        {{ item.last_changed | formatDate }}
      </template>

      <template v-slot:item.actions="{ item }">
        <editAndDeleteClient listType="registered" :clientObj="item">
        </editAndDeleteClient>
        <v-btn
          class="ml-2"
          @click="displayProductCustomPrice(item)"
          outlined
          x-small
        >
          Price List
        </v-btn>
      </template>
    </v-data-table>
    <productCustomPrice
      :clientObj="selectedClientObj"
      @goBack="showProductCustomPrice = false"
      v-if="showProductCustomPrice"
    ></productCustomPrice>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import editAndDeleteClient from "@/components/admin/clientManagement/editAndDeleteClient.vue";
import productCustomPrice from "@/components/admin/clientManagement/productCustomPrice.vue";
import moment from "moment";
export default {
  name: "registeredClientsList",
  data() {
    return {
      loading: false,
      showProductCustomPrice: false,
      search: null,
      selectedClientObj: null,
      header: [
        { text: "Name", align: "start", value: "name" },
        { text: "Email", align: "start", value: "email" },
        { text: "Office Name", align: "start", value: "officeName" },
        { text: "Client Type", align: "center", value: "clientType" },
        { text: "Status", align: "center", value: "onlineStatus" },
        { text: "Last Online", align: "center", value: "last_changed" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
    };
  },
  components: {
    editAndDeleteClient,
    productCustomPrice,
  },
  computed: {
    ...mapGetters(["getRegisteredClientsList"]),
  },
  methods: {
    displayProductCustomPrice(clientObj) {
      this.showProductCustomPrice = true;
      this.selectedClientObj = clientObj;
    },
    getColor(status) {
      if (status.toLowerCase() === "unavailable") return "error";
      if (status.toLowerCase() === "busy") return "amber";
      if (status.toLowerCase() === "available") return "success";
    },
  },
  mounted() {
    if (this.getRegisteredClientsList.length === 0) {
      this.loading = true;
      this.$store.dispatch("fetchRegisteredClientsList").then(
        (response) => {
          console.log(response);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    }
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
  },
};
</script>