import router from "@/router";
// import store from "../index";
import * as fb from "../../firebase";




export default {
  state: {
    user: {},
    invitedUser: {}

  },
  getters: {
    getUser: state => state.user,
    getInvitedUser: state => state.invitedUser

  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setInvitedUser(state, data){
      state.invitedUser = data;
    }
  },
  actions: {
    createAdminAccount({ dispatch }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.createUserWithEmailAndPassword(form.email, form.password)
          .then(cred => {
            //create user profile in usercollection
            fb.userCollection.doc(cred.user.uid).set({
              name: form.name,
              email: form.email,
              userRole: form.userRole,
              createdOn: new Date(),
              updatedOn: new Date(),
              onlineStatus: 'Unavailable'
            }).then(() => {
              dispatch('initiateAppData')
                .then(() => {
                  console.log('app initiated');
                  resolve('Account Created Successfully.');
                })
            })
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    login({ dispatch }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.signInWithEmailAndPassword(form.email, form.password).then(cred => {
          console.log(`user logged In: ${cred.user.uid}`);
          //fetch user profile
          dispatch('fetchUserProfile', cred.user)
            .then((response) => {
              console.log(response);
              resolve('Successfully Logged In');
            })
        })
          .catch(error => {
            reject(error.message)
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    fetchUserProfile({ commit }, user) {
      return new Promise((response, reject) => {
        //fetch user profile data
        fb.userCollection.doc(user.uid)
          .onSnapshot(userData => {
            //set userProfile in state
            commit('setUser', userData);
            //change route to dashboard
            if (router.currentRoute.path === '/login' || router.currentRoute.path === '/admin') {
              router.push('/')
              response('User Profile fetched successfully')
            }
          });
      })
    },
    logout({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        //set user Unavailable
        dispatch('setUserUnavailable');
        fb.auth.signOut()
          .then(() => {
            commit('setUser', {});
            router.push('/login');
            resolve('Successfully Logged Out')
            location.reload();
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },
    setUserAvailable({ state }) {
      fb.userCollection.doc(state.user.id).update({
        onlineStatus: 'Available',
      })
    },
    setUserBusy({ state }) {
      fb.userCollection.doc(state.user.id).update({
        onlineStatus: 'Busy',
      })
    },
    setUserUnavailable({ state }) {
      fb.userCollection.doc(state.user.id).update({
        onlineStatus: 'Unavailable',
      })
    },
    inviteNewUser({ dispatch }, user) {
      return new Promise((resolve, reject) => {
        console.log(user)
        fb.invitedUsersCollection.add({
          name: user.name,
          email: user.email,
          userRole: user.userRole,
          link: user.fdn,
          createdOn: new Date(),
        }).then((userRef) => {
          console.log(userRef.id)
          let link = user.fdn + '/registration/' + userRef.id;
          console.log(link)
          fb.invitedUsersCollection.doc(userRef.id).update({
            link: link
          })
            .then(() => {
              dispatch('userInvitationEmail', { name: user.name, email: user.email, link: link })
                .then(() => {
                  resolve('Invitation sent successfully.');
                }, error => {
                  reject(error)
                })
            })
            .catch(error => {
              reject(error.message);
            })
        })
          .catch(error => {
            console.log(error.message)
            reject(error.message);
          })

      })
    },
    getInvitedUserInformation({ commit }, uId) {
      return new Promise((resolve, reject) => {
        fb.invitedUsersCollection.doc(uId).get()
          .then(userData => {
            commit('setInvitedUser', userData);
            resolve('Fetched User Information');
          })
          .catch(error => {
            reject(error.message);
          })
      })
    },
    createInvitedUserAccount({ commit }, form) {
      return new Promise((resolve, reject) => {
        fb.auth.createUserWithEmailAndPassword(form.email, form.password)
          .then(cred => {
            //create user profile in usercollection
            fb.userCollection.doc(cred.user.uid).set({
              name: form.name,
              email: form.email,
              userRole: form.userRole,
              createdOn: new Date(),
              updatedOn: new Date(),
              onlineStatus: 'Unavailable'
            }).then(() => {
              fb.invitedUsersCollection.doc(form.invitationId).delete()
                .then(() => {
                  fb.auth.signOut();
                  commit('setUser', {});
                  resolve('Account Created Successfully. Login to Continue');
                  router.push('/login');
                })
            })
          })
          .catch(error => {
            reject(error.message);
            //Todo: add logging mechanism to be stored on firebase for each account
          })
      })
    },


    manageUserPresence({ state, dispatch }, user) {
      var userStatusDatabaseRef = fb.firebase.database().ref('/status/' + user.uid);

      var isOfflineForDatabase = {
        state: 'Unavailable',
        last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
      };

      var isOnlineForDatabase = {
        state: 'Available',
        last_changed: fb.firebase.database.ServerValue.TIMESTAMP,
      };

      fb.firebase.database().ref('.info/connected').on('value', function (snapshot) {
        // If we're not currently connected, don't do anything.
        if (snapshot.val() === false) return;

        userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
          userStatusDatabaseRef.set(isOnlineForDatabase);
        });
      });
    },


  }
}