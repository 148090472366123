<template>
    <v-dialog
            v-model="show"
            :width="isPhone ? '95%' : '40%'">
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    class="mr-2"
                    @click="editUser">
                mdi-pencil
            </v-icon>
            <v-icon
                    v-bind="attrs"
                    v-on="on"
                    small
                    @click="deleteUser">
                mdi-delete
            </v-icon>
        </template>
        <v-card >
            <div style="position: absolute; align-self: end; right: 0">
                <v-btn
                        icon
                        @click="show = !show"
                        class="ma-1" >
                    <v-icon color="red">mdi-window-close</v-icon>
                </v-btn>
            </div>
            <v-card-title
                    class="justify-center">
                {{formTitle}}
            </v-card-title>
            <v-divider></v-divider>
            <v-progress-linear
                    v-show="loading"
                    indeterminate
                    color="primary"
                    background-opacity="0">
            </v-progress-linear>
            <v-card-text v-if="actionType==='edit'">
                <v-form
                        ref="editUserForm"
                        lazy-validation
                        class="px-6">
                    <v-text-field
                            v-model="user.name"
                            label="Full Name"
                            :rules="rules.nameRules"
                            required>
                    </v-text-field>
                    <v-text-field
                            disabled
                            v-model="user.email"
                            label="E-mail"
                            :rules="rules.emailRules"
                            required>
                    </v-text-field>
                    <v-select
                            v-model="user.userRole"
                            :items="roles"
                            :rules="[v => !!v || 'User role is required']"
                            label="Select User Role"
                            required>
                    </v-select>                    
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                outlined
                                text
                                @click="show = !show">
                            Cancel
                        </v-btn>
                        <v-btn
                                color="primary"
                                elevation="0"
                                @click="updateUser">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
            <v-card-actions v-if="actionType==='delete'">
                <v-spacer></v-spacer>
                <v-btn color="primary " outlined elevation="0" text @click="show = ! show">Cancel</v-btn>
                <v-btn color="primary " elevation="0"  @click="confirmDeleteUser">Delete</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
  import {mapGetters} from 'vuex';

  export default {
    name: 'editAndDeleteUser',
    props:{
      userObj: Object,
      listType: String
    },
    data(){
      return{
        show: null,
        actionType: '',
        formTitle: '',
        loading: false,
        roles: ["Admin", "General"],
        user:{
          name: '',
          email: '',
          userRole: '',          
          id: null,
        },
        rules:{
          nameRules: [
            v => !!v || 'Full name is required',
          ],
          emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
          ],
        }
      }
    },
    methods:{
      editUser(){
        this.formTitle = "Edit User";
        this.actionType = 'edit';        
        this.user = Object.assign({}, this.userObj.data());
        this.user.id= this.userObj.id;
        console.log(this.user)
      },
      deleteUser(){
        this.formTitle = "Delete user?";
        this.actionType = 'delete';
      },
      updateUser(){
        if(this.$refs.editUserForm.validate()){
          this.loading = true;
          if(this.listType === 'registered'){
            this.$store.dispatch('updateRegisteredUserInformation', this.user)
              .then(response => {
                this.loading = false;
                this.show=false;
                this.$store.dispatch('showSnackBar', {
                  text: response,
                  color: 'success'
                })
              }, error => {
                this.$store.dispatch('showSnackBar', {
                  text: error,
                  color: 'error'
                })
              })
          }
          if(this.listType === 'invited'){
            this.$store.dispatch('updateInvitedUserInformation', this.user)
              .then(response => {
                this.loading = false;
                this.show=false;
                this.$store.dispatch('showSnackBar', {
                  text: response,
                  color: 'success'
                })
              }, error => {
                this.$store.dispatch('showSnackBar', {
                  text: error,
                  color: 'error'
                })
              })
          }

        }
      },
      confirmDeleteUser(){
        this.loading = true;
        if(this.listType === 'registered'){
          this.$store.dispatch('deleteRegisteredUser', this.userObj.id)
            .then(response => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch('showSnackBar', {
                text: response,
                color: 'success'
              })
            }, error => {
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error'
              })
            })
        }
        if(this.listType === 'invited'){
          this.$store.dispatch('deleteInvitedUser', this.userObj.id)
            .then(response => {
              this.loading = false;
              this.show = false;
              this.$store.dispatch('showSnackBar', {
                text: response,
                color: 'success'
              })
            }, error => {
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error'
              })
            })
        }
      }

    },
    computed:{
      ...mapGetters(["isPhone"]),
      
    },
    mounted(){
      
    }
  }
</script>