<template>
<h1>General Home</h1>
    
</template>

<script>
export default {
    name: 'generalHome'
}
</script>

