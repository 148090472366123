import * as fb from "../../firebase";

export default {
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        userInvitationEmail({ state }, user) {
            return new Promise((response, reject) => {
                const temp = 'Hello ' + user.name + ', <br/> ' +
                    '<br/>' +
                    'Your RL Deltal Lab account is created successfully. <br/>' +
                    '<br/>' +
                    'Please complete your registration with this following link <br/> ' +
                    '<br/> ' +
                    '<a href='+ user.link +'> <strong>' + user.link + '</strong></a>' +
                    '<br/>' +
                    '<br/>' +
                    'Thank You, <br/>' +
                    '<br/>' +
                    'Team RL Dental Lab'

                fb.mailCollection.add({

                    to: [user.email],
                    message: {
                        subject: 'Registeration: Welcome to RL Dental Lab',
                        html: temp
                    }

                }).then(() => {
                    response('Email sent successfully');
                })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        clientInvitationEmail({ state }, client) {
            return new Promise((response, reject) => {
                const temp = 'Hello ' + client.name + ', <br/> ' +
                    '<br/>' +
                    'Your RL Deltal Lab account is created successfully. <br/>' +        
                    '<br/>' +                    
                    'Please complete your registration with this following link <br/> ' +
                    '<br/> ' +
                    '<a href='+ client.regLink +'> <strong>' + client.regLink + '</strong></a>' +
                    '<br/>' +
                    '<br/>' +
                    'Thank You, <br/>' +
                    '<br/>' +
                    'Team RL Dental Lab'

                fb.mailCollection.add({

                    to: [client.email],
                    message: {
                        subject: 'Registeration: Welcome to RL Dental Lab',
                        html: temp
                    }

                }).then(() => {
                    response('Email sent successfully');
                })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        }
    }
}