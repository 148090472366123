import * as fb from "../../firebase";

export default {
    state: {
        registeredUsersList: [],
        invitedUsersList: [],

    },
    getters: {
        getRegisteredUsersList: state => state.registeredUsersList,
        getInvitedUsersList: state => state.invitedUsersList

    },
    mutations: {
        setRegisteredUsersList(state, users) {
            state.registeredUsersList = users;
        },
        setInvitedUsersList(state, users) {
            state.invitedUsersList = users
        }
    },
    actions: {
        fetchRegisteredUsersList({ commit }) {
            return new Promise((response, reject) => {
                fb.userCollection.where('userRole', '!=', 'client').onSnapshot(snapshot => {
                    if (snapshot.empty) reject('Cannot find any registerd users.');
                    commit('setRegisteredUsersList', snapshot.docs);
                    response('Fetched registered users successfully');
                }, error => {
                    reject(error.message);
                })
            })
        },
        fetchInvitedUsersList({ commit }) {
            return new Promise((response, reject) => {
                fb.invitedUsersCollection.where('userRole', '!=', 'client').onSnapshot(snapshot => {
                    if (snapshot.empty) reject('Cannot find any invited users.');
                    commit('setInvitedUsersList', snapshot.docs);
                    response('Fetched invited users successfully');
                }, error => {
                    reject(error.message);
                })
            })
        },
        updateRegisteredUserInformation({ state }, userData) {
            return new Promise((resolve, reject) => {
                fb.userCollection.doc(userData.id).update({
                    name: userData.name,
                    userRole: userData.userRole,
                    updatedOn: new Date()
                })
                    .then(() => {
                        resolve('User Information updated successfully.')
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        updateInvitedUserInformation({ state }, userData) {
            return new Promise((resolve, reject) => {
                fb.invitedUsersCollection.doc(userData.id).update({
                    name: userData.name,
                    userRole: userData.userRole,
                })
                    .then(() => {
                        resolve('User Information updated successfully.')
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        deleteRegisteredUser({state}, userId){
            return new Promise((resolve, reject) => {
              fb.userCollection.doc(userId).delete()
                .then(() => {
                  resolve('User deleted successfully')
                })
                .catch(error => {
                  reject(error.message);
                })
            })
          },
          deleteInvitedUser({state}, userId){
            return new Promise((resolve, reject) => {
              fb.invitedUsersCollection.doc(userId).delete()
                .then(() => {
                  resolve('User deleted successfully')
                })
                .catch(error => {
                  reject(error.message);
                })
            })
          }
    }
}