import Vue from 'vue'
import VueRouter from 'vue-router'
import {auth} from "@/firebase";
import home from '../views/home.vue'
import login from '@/views/login.vue'
import adminLogin from '@/views/admin/adminLogin.vue'
import notFound from '@/views/notFound.vue'
import userManagement from '@/views/admin/userManagement.vue'
import clientManagement from '@/views/admin/clientManagement.vue'
import productManagement from '@/views/admin/productManagement.vue'
import dashboard from '@/views/dashboard.vue'
import createNewJob from '@/views/admin/createNewJob.vue'
import jobManagement from '@/views/admin/jobManagement.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Not Found',
    component: notFound,
    meta:{
      title: '404'
    }
  },
  {
    path: '/',    
    component: dashboard,
    meta:{
      requiresAuth: true,
      
    },
    children:[
      {
        path: '/',
        name: 'home',
        component: home,
        meta:{
          title: 'Home'
        }
      },
      {
        path: '/userManagement',
        name: 'userManagement',
        component: userManagement,
        meta:{
          title: 'User Management'
        }
      },
      {
        path: '/clientManagement',
        name: 'clientManagement',
        component: clientManagement,
        meta:{
          title: 'Client Management'
        }
      },
      {
        path: '/productManagement',
        name: 'productManagement',
        component: productManagement,
        meta:{
          title: 'Product Management'
        }
      },
      {
        path: '/createJob',
        name: 'createNewJob',
        component: createNewJob,
        meta:{
          title: 'New Job'
        }
      },
      {
        path: '/jobManagement',
        name: 'jobManagement',
        component: jobManagement,
        meta:{
          title: 'Job Management'
        }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta:{
      requiresGuest: true,
      title: 'Login'
    }
  },
  {
    path: '/admin',
    name: 'adminLogin',
    component: adminLogin,
    meta:{
      requiresGuest: true,
      title: 'Admin'
    }
  },
  {
    path: '/registration/:id',
    name: 'Registration',
    component: () => import('../views/invitedUserRegistration'),
    meta:{
      requiresGuest: true,
      title: 'Registration'
    }
  },
  {
    path: '/clientRegistration/:id',
    name: 'Registration',
    component: () => import('../views/invitedClientRegisteration'),
    meta:{
      requiresGuest: true,
      title: 'Registration'
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  if(requiresAuth && !auth.currentUser){
    next('/login');
  }
  else{
    next();
  }
})

router.afterEach((to, from) => {  
  const DEFAULT_TITLE = 'RL Dashboard';
  
  Vue.nextTick(() => {
      document.title = ` ${to.meta.title} | ${DEFAULT_TITLE} `;
  });
});

export default router
