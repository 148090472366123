import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database'

const firebaseConfig = {
  apiKey: "AIzaSyBDVYkDFUyipD57APmRk5epY0y1XpcXbgQ",
  authDomain: "rl-dental-lab.firebaseapp.com",
  projectId: "rl-dental-lab",
  storageBucket: "rl-dental-lab.appspot.com",
  messagingSenderId: "977898768788",
  appId: "1:977898768788:web:ef04f30821711dbe68e781",
  measurementId: "G-G55WV1WTEM",
  databaseURL: 'https://rl-dental-lab-default-rtdb.asia-southeast1.firebasedatabase.app/'
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);



const db = firebase.firestore();
const auth=firebase.auth();

const userCollection = db.collection('users');
const appDataCollection = db.collection('appData');
const invitedUsersCollection = db.collection('invitedUsers');
const mailCollection = db.collection('mail');
const productCollection = db.collection('products');


export{
  db,
  auth,
  firebase,
  userCollection,
  appDataCollection,
  invitedUsersCollection,
  mailCollection,
  productCollection
}