<template>
  <v-data-table
    :headers="headers"
    :items="getRegisteredUsersList"
    :loading="loading"
  >
    <template v-slot:item.name="{ item }">
      {{ item.data().name }}
    </template>
    <template v-slot:item.email="{ item }">
      {{ item.data().email }}
    </template>
    <template v-slot:item.userRole="{ item }">
      {{ item.data().userRole }}
    </template>
    <template v-slot:item.onlineStatus="{ item }">
      <v-chip dark small :color="getColor(item.data().onlineStatus)">
        {{ item.data().onlineStatus }}
      </v-chip>
    </template>
    <template v-slot:item.createdOn="{ item }">
      {{ item.data().createdOn | formatDate }}
    </template>
    <template v-slot:item.last_changed="{ item }">
      {{ item.data().last_changed | formatDate }}
    </template>
    <template v-slot:item.actions="{ item }">
      <editAndDeleteUser listType="registered" :userObj="item">
      </editAndDeleteUser>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import editAndDeleteUser from "@/components/admin/userManagement/editAndDeleteUser.vue";

export default {
  name: "registeredUsersList",
  components: {
    editAndDeleteUser,
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Name", align: "start", value: "name" },
        { text: "Email", align: "start", value: "email" },
        { text: "Roles", value: "userRole" },
        { text: "Status", align: "center", value: "onlineStatus" },
        { text: "Last Online", align: "center", value: "last_changed" },
        { text: "Joined", align: "center", value: "createdOn" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["getRegisteredUsersList"]),
  },
  methods: {
    getColor(status) {
      if (status.toLowerCase() === "unavailable") return "error";
      if (status.toLowerCase() === "busy") return "amber";
      if (status.toLowerCase() === "available") return "success";
    },
  },
  mounted() {
    if (this.getRegisteredUsersList.length === 0) {
      this.loading = true;
      this.$store.dispatch("fetchRegisteredUsersList").then(
        (response) => {
          console.log(response);
          this.loading = false;
        },
        (error) => {
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    }
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
  },
};
</script>