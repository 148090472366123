<template>
  <v-dialog v-model="show" :width="isPhone ? '95%' : '40%'">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        elevation="0"
        class="d-none d-sm-flex"
        color="primary"
      >
        <v-icon left> mdi-plus </v-icon>
        Add Product
      </v-btn>
      <v-btn
        v-bind="attrs"
        v-on="on"
        elevation="0"
        icon
        small
        class="d-flex d-sm-none"
        color="primary"
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <div style="position: absolute; align-self: end; right: 0">
        <v-btn icon @click="show = !show" class="ma-1">
          <v-icon color="red">mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="justify-center"> Add New Product </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear
        v-show="loading"
        indeterminate
        color="primary"
        background-opacity="0"
      >
      </v-progress-linear>
      <v-card
        :max-height="viewPortHeight / 1.4"
        class="overflow-y-auto overflow-x-hidden px-6 py-3"
      >
        <v-form ref="productForm" lazy-validation>
          <v-text-field
            v-model="product.name"
            :rules="rules.emptyRule"
            label="Product Name"
            required
            autocomplete="chrome-off"
          >
          </v-text-field>
          <v-textarea
            v-model="product.description"
            rows="1"
            label="Description"
            autocomplete="chrome-off"
          >
          </v-textarea>
          <v-text-field
            v-model="product.price"
            type="number"
            label="Default Price"
            :rules="rules.emptyRule"
            prepend-icon="mdi-currency-inr"
            autocomplete="chrome-off"
          >
          </v-text-field>
          <v-checkbox
            v-model="product.warrantySupported"
            label="Support Warranty?"
          ></v-checkbox>
          <v-card class="px-3" outlined v-if="product.warrantySupported">
            <v-row v-for="(warranty, i) in product.warrantys" :key="i">
              <v-col cols="3" md="3">
                <v-text-field
                  v-model="product.warrantys[i].warrantyTermLength"
                  :rules="rules.emptyRule"
                  type="number"
                  label="Length"
                  placeholder="Eg. 1"
                  autocomplete="chrome-off"
                >
                </v-text-field>
              </v-col>
              <v-col cols="3" md="3">
                <v-select
                  v-model="product.warrantys[i].warrantyTerm"
                  :rules="rules.emptyRule"
                  label="Term"
                  :items="terms"
                  required
                  autocomplete="chrome-off"
                >
                </v-select>
              </v-col>
              <v-col cols="4" md="3">
                <v-text-field
                  v-model="product.warrantys[i].warrantyPrice"
                  :rules="rules.emptyRule"
                  type="number"
                  label="Price"
                  prepend-icon="mdi-currency-inr"
                  autocomplete="chrome-off"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-btn
                  @click="product.warrantys.splice(i, 1)"
                  class="mt-5"
                  outlined
                  small
                  icon
                  color="error"
                >
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-btn
              text
              outlined
              small
              color="primary"
              @click="
                product.warrantys.push({
                  warrantyTermLength: null,
                  warrantyTerm: null,
                  warrantyPrice: null,
                })
              "
            >
              <v-icon left>mdi-plus</v-icon> Add Warranty</v-btn
            >
            <v-textarea
              v-model="product.warrantyCondition"
              rows="1"
              label="Warranty Terms and Conditions"
              autocomplete="chrome-off"
            >
            </v-textarea>
          </v-card>
          <v-btn
            color="primary"
            dark
            block
            large
            elevation="0"
            @click="addProduct()"
          >
            Add Product
          </v-btn>
        </v-form>
      </v-card>
      <v-divider></v-divider>
      <v-card-text align="center" class="caption mt-2">
        <v-icon small left>mdi-information-outline</v-icon>
        Custom product cost can be adjusted under Client's Profile.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "createNewProduct",
  data() {
    return {
      show: null,
      loading: false,
      product: {
        name: null,
        description: null,
        price: null,
        warrantySupported: false,
        warrantys: [
          { warrantyTermLength: null, warrantyTerm: null, warrantyPrice: null },
        ],
        warrantyCondition: null,
        isEnabled: false,
        createdOn: new Date(),
        updatedOn: new Date(),
        createdBy: null,
      },
      rules: {
        emptyRule: [(v) => !!v || "Cannot be left empty."],
      },
      terms: ["Year", "Month"],
    };
  },
  computed: {
    ...mapGetters(["isPhone", "getUser"]),
    viewPortHeight() {
      return window.innerHeight;
    },
  },
  watch: {
    show(o, n) {
      if (n) {
        this.$refs.productForm.reset();
      }
    },
  },
  methods: {
    addProduct() {
      if (this.$refs.productForm.validate()) {
        this.loading = true;
        this.product.createdBy = this.getUser.data().name;
        if (!this.product.warrantySupported) {
          this.product.warrantys = [];
          this.product.warrantyCondition = null;
        }
        this.$store.dispatch("addNewProduct", this.product).then(
          (response) => {
            this.loading = false;
            this.$refs.productForm.reset();
            this.show = false;
            this.$store.dispatch("showSnackBar", {
              text: response,
              color: "green",
            });
          },
          (error) => {
            this.$store.dispatch("showSnackBar", {
              text: error,
              color: "error",
            });
          }
        );
      }
    },
  },
};
</script>