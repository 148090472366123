<template>
<div>
    <h1>Admin Home</h1>
      <v-container>
        <v-text-field v-model="value"></v-text-field>
          <qrcode :text="value"></qrcode>
      </v-container>    
  </div>
    
</template>

<script>
import qrcode from 'vue-qr-generator'

export default {
    name: 'adminHome',
    components:{
        qrcode
    },
    data(){
        return{
            value:''
        }
    }
}
</script>

