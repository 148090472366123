<template>
  <v-data-table
    :headers="header"
    :loading="loading"
    :items="getAllProductList"
    item-key="name"
    :search="search"
  >
    <template v-slot:top>
      <v-text-field
        v-model="search"
        label="Search by Product Name"
        class="mx-4"
      ></v-text-field>
    </template>
    <template v-slot:item.updatedOn="{ item }">
      {{ item.updatedOn | formatDate }}
    </template>
    <template v-slot:item.price="{ item }"> ₹ {{ item.price }}/- </template>
    <template v-slot:item.warrantySupported="{ item }">
      <v-menu
        v-if="item.warrantySupported"
        max-height="300"
        bottom
        right
        transition="scale-transition"
        origin="top left"
      >
        <template v-slot:activator="{ on }">
          <span
            v-on="on"
            v-for="(warranty, index) in item.warrantys"
            :key="index"
          >
            <v-chip
              class="mr-1"
              outlined
              color="success darken-1"
              small
              v-if="index === 0"
            >
              <span
                >{{ warranty.warrantyTermLength }}
                {{ warranty.warrantyTerm }}</span
              >
            </v-chip>
            <span v-if="index === 1" class="grey--text caption">
              (+ {{ item.warrantys.length - 1 }} more)
            </span>            
          </span>
        </template>
        <v-card width="200">
          <v-list>
            <v-list-item
              v-for="(warranty, index) in item.warrantys"
              :key="index"
            >
              <v-row>
                <v-col>
                  <v-chip outlined color="success darken-1" small>
                    <span
                      >{{ warranty.warrantyTermLength }}
                      {{ warranty.warrantyTerm }}
                    </span>
                  </v-chip>
                </v-col>
                <v-col> ₹ {{ warranty.warrantyPrice }}/- </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-chip v-else small outlined color="error"> No </v-chip>
    </template>

    <template v-slot:item.isEnabled="{ item }">
      <v-switch
        :input-value="item.isEnabled"
        @change="productStateChange(item)"
        color="success"
        inset
        dense
      ></v-switch>
    </template>
    <template v-slot:item.actions="{ item }">
      <editAndDeleteProduct :productObj="item"></editAndDeleteProduct>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import editAndDeleteProduct from "@/components/admin/productManagement/editAndDeleteProduct.vue";

export default {
  name: "productList",
  data() {
    return {
      showWarrantyDetails: false,
      loading: false,
      search: null,
      header: [
        { text: "Name", align: "start", value: "name" },
        { text: "Price (₹)", align: "start", value: "price" },
        { text: "Last Updated", align: "start", value: "updatedOn" },
        {
          text: "Warranty Supported",
          align: "center",
          value: "warrantySupported",
        },
        { text: "Added By", align: "start", value: "createdBy" },
        { text: "Is Enabled", align: "start", value: "isEnabled" },
        { text: "Actions", align: "start", value: "actions" },
      ],
    };
  },
  components: {
    editAndDeleteProduct,
  },
  computed: {
    ...mapGetters(["getAllProductList"]),
  },
  methods: {
    productStateChange(item) {
      this.$store
        .dispatch("updateProduct", {
          id: item.id,
          isEnabled: !item.isEnabled,
          updatedOn: new Date(),
        })
        .then(
          (response) => {
            this.$store.dispatch("showSnackBar", {
              text: response,
              color: "green",
            });
          },
          (error) => {
            this.$store.dispatch("showSnackBar", {
              text: error,
              color: "error",
            });
          }
        );
    },
  },
  mounted() {
    if (this.getAllProductList.length === 0) {
      this.loading = true;
      this.$store.dispatch("fetchProductList").then(
        (response) => {
          this.loading = false;
          this.$store.dispatch("showSnackBar", {
            text: response,
            color: "green",
          });
        },
        (error) => {
          this.loading = false;
          this.$store.dispatch("showSnackBar", {
            text: error,
            color: "error",
          });
        }
      );
    }
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).fromNow();
    },
  },
};
</script>
