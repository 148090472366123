<template>
  <v-footer class="main">
    <v-col cols="12" class="text-center grey--text caption">
      {{ new Date().getFullYear() }} — <strong>RL Dental Lab </strong>
      <span class="text-caption">
        (Beta) - Made with
        <v-icon color="red darken-3" x-small>mdi-heart</v-icon> in India
      </span>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "dasfooter",
};
</script>