<template>
    <v-container
            fluid >
        <v-card
                elevation="0"
                class="px-3">
            <v-card-title
                    class="text-sm-h5 text-lg-h4">
                Client Management
                <v-spacer></v-spacer>
                <createNewClient></createNewClient>
                
            </v-card-title>
            
        </v-card>

        <v-card
                elevation="0"
                class="px-3 mt-3" >
            <v-tabs v-model="tab">
                <v-tab>Registered Clients</v-tab>
                <v-tab>Invited Clients</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">

                <v-tab-item>
                    <registeredClientsList></registeredClientsList>
                </v-tab-item>
                <v-tab-item>
                    <invitedClientsList></invitedClientsList>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
    
</template>

<script>
import createNewClient from '@/components/admin/clientManagement/createNewClient.vue';
import invitedClientsList from '@/components/admin/clientManagement/invitedClientsList.vue';
import registeredClientsList from '@/components/admin/clientManagement/registeredClientsList.vue';

export default {
    name: 'clientManagement',
    components:{
        createNewClient,
        invitedClientsList,
        registeredClientsList
    },
    data(){
        return{
            tab: null

        }
    }
}
</script>