<template>
  <v-dialog v-model="show" :max-width="isPhone ? '95%' : '40%'">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        elevation="0"
        class="d-none d-sm-flex"
        color="primary"
      >
        <v-icon left> mdi-account-plus </v-icon>
        Create New User
      </v-btn>
      <v-btn
        v-bind="attrs"
        v-on="on"
        elevation="0"
        icon
        small
        class="d-flex d-sm-none"
        color="primary"
      >
        <v-icon> mdi-account-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <div style="position: absolute; align-self: end; right: 0">
        <v-btn icon @click="show = !show" class="ma-1">
          <v-icon color="red">mdi-window-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="justify-center"> Create New User </v-card-title>
      <v-divider></v-divider>
      <v-progress-linear
        v-show="loading"
        indeterminate
        color="primary"
        background-opacity="0"
      >
      </v-progress-linear>
      <v-card-text>
        <v-form ref="inviteNewUserForm" lazy-validation class="px-6">
          <v-text-field
            v-model="user.name"
            label="Full Name"
            :rules="rules.nameRules"
            required
          >
          </v-text-field>
          <v-text-field
            v-model="user.email"
            label="E-mail"
            :rules="rules.emailRules"
            required
          >
          </v-text-field>
          <v-select
            v-model="user.userRole"
            :items="roles"
            :rules="[(v) => !!v || 'User role is required']"
            label="User Role"
            required
          >
          </v-select>

          <v-btn color="primary" dark block large elevation="0" @click="inviteNewUser()">
            Create and Invite User
          </v-btn>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text align="center" class="caption">
        <p>
          Invitation link sent to the user's email will be use to set the
          password and activate the account.
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "createNewUser",
  data() {
    return {
      show: null,
      loading: false,
      user: {
        name: "",
        email: "",
        userRole: "",
        fdn: window.location.origin,
      },
      rules: {
        nameRules: [(v) => !!v || "Full name is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
      },
      roles: ["Admin", "General"],
    };
  },
  computed: {
    ...mapGetters(["isPhone"]),
  },
  methods: {
    inviteNewUser() {
      if (this.$refs.inviteNewUserForm.validate()) {
        this.loading = true;
        this.$store.dispatch("inviteNewUser", this.user).then(
          (response) => {
            this.loading = false;
            this.$refs.inviteNewUserForm.reset()
            this.show = false;
            this.$store.dispatch("showSnackBar", {
              text: response,
              color: "green",
            });
          },
          (error) => {
            this.$store.dispatch("showSnackBar", {
              text: error,
              color: "error",
            });
          }
        );
      }
    },
  },
};
</script>