<template>
    <v-navigation-drawer  v-model="drawerState"  clipped-left app>
        <v-app-bar flat>        
        <v-img
        src="@/assets/logo.png"
        contain
        justify="center"
        max-height="50"
        max-width="200"
        class="my-auto"
      >
      </v-img>
      
    </v-app-bar>

    <v-divider></v-divider>
        General Drawer
    </v-navigation-drawer>
</template>

<script>
export default {
    name: 'adminDrawer',
    data(){
        return{
        }
    },
    computed:{
        drawerState:{
            get() {return this.$store.getters.getDrawerState},
            set(v) {return this.$store.commit("setDrawerState", v)},
        }        
    },
    methods:{        
    }
}
</script>