import { reject } from "lodash";
import * as fb from "../../firebase";

export default {
    state: {
        product: {},
        allProductsMap: new Map(),
        allProducts: []
    },
    getters: {
        getAllProductList: state => state.allProducts,
        getAllProductMap: state => state.allProductsMap

    },
    mutations: {
        setProductMap(state, product) {
            state.allProductsMap.set(product.id, { ...product.data(), id: product.id })
        },
        setProductList(state, productMap) {
            state.allProducts = Array.from(productMap.values());
        },
        removeProductFromMap(state, productId) {
            state.allProductsMap.delete(productId);
        }

    },
    actions: {
        addNewProduct({ state }, product) {
            return new Promise((response, request) => {
                fb.productCollection.add(product)
                    .then(productRef => {
                        console.log(`Product added successfully: ${productRef}`)
                        response('Product added successfully')
                    })
                    .catch(error => {
                        console.log(error.message)
                        reject(error.message)
                    })

            })
        },
        fetchProductList({ state, commit }) {
            return new Promise((response, reject) => {
                fb.productCollection.onSnapshot(snapshot => {
                    if (snapshot.empty) reject('No product added yet.')
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === "removed") {
                            commit('removeProductFromMap', change.doc.id);
                        }
                    });
                    snapshot.forEach(doc => {
                        commit('setProductMap', doc)
                    })
                    commit('setProductList', state.allProductsMap);
                    response('Product list fetched successfully');
                }, error => {
                    console.log(error.message)
                    reject(error.message);
                })
            })
        },
        updateProduct({ state }, item) {
            return new Promise((response, reject) => {
                fb.productCollection.doc(item.id).update(item)
                    .then(() => {
                        console.log(`Product updated: ${item.id}`)
                        response('Product updated successfully')
                    })
                    .catch(error => {
                        console.log(error.message)
                        reject(error.message)
                    })
            })
        },
        deleteProduct({ state }, productId) {
            return new Promise((response, reject) => {
                fb.productCollection.doc(productId).delete()
                    .then(() => {
                        response('Product Deleted successfully.')
                    })
                    .catch(error => {
                        reject(error.message);
                    })
            })
        },
        updateCustomPrice({state}, customObj){
            return new Promise((response, reject) => {
                fb.userCollection.doc(customObj.userId).update({customProductList: customObj.productList})
                .then(() => {
                    response('Custom price updated successfully');
                })
                .catch(error => {
                    reject(error.message);
                })
            })
        }

    }
}